import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { Checkbox, Form, Input } from "antd";
import { TfiEmail } from "react-icons/tfi";
import { CiLock } from "react-icons/ci";
import { psApiCalling } from "../../../component/API/index";

export default function Login() {
  const onFinish = (values) => {
    console.log("Success:", values);
    let params = {
      action: "ADMIN_LOGIN",
      email: values.email,
      password: values.password,
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        localStorage.setItem("aid", res.aid);
        localStorage.setItem("aname", res.aname);
        localStorage.setItem("aotp", res.aotp);
        window.location.href = "/admin_otp";
      } else {
        alert(res.message);
      }
    });
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <Container>
      <Row>
        <Col md={4}></Col>
        <Col
          md={4}
          className="splashMainContainer"
          style={{ marginTop: "50px", padding: "0px" }}
        >
          <div
            style={{
              width: "100%",
              height: "150px",
              background: "#000000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ color: "#ffffff" }}>Address Book</h3>
          </div>
          <Form
            name={"complex"}
            layout="vertical"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: "85%",
              marginTop: "50px",
              height: "400px",
              marginLeft: "7.5%",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                placeholder={"Email Address"}
                prefix={<TfiEmail color="#00817f" />}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password prefix={<CiLock color="#00817f" />} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" className="loginBtn">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}
