import "bootstrap/dist/css/bootstrap.min.css";
import Router from "../src/Router/Router";
import "@coreui/coreui/dist/css/coreui.min.css";
function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
