import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { Checkbox, Form, Input } from "antd";
import { TfiEmail } from "react-icons/tfi";
import { CiLock } from "react-icons/ci";
import { psApiCalling } from "../../component/API/index";
import { Modal } from "antd";
import "./Signup.css";
export default function Signup() {
  const [showTerm, setShowTerm] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const handleTerm = () => {
    setShowTerm(false);
  };
  const handleCancelTerm = () => {
    setShowTerm(false);
  };
  const handlePrivacy = () => {
    setShowPrivacy(false);
  };
  const handleCancelPrivacy = () => {
    setShowPrivacy(false);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    if (values.password === values.re_pass) {
      let params = {
        action: "CREATE_USER",
        fullname: values.fullname,
        email: values.email,
        password: values.password,
      };
      psApiCalling(params).then((res) => {
        if (res.status === "success") {
          localStorage.setItem("uniid", res.id);
          localStorage.setItem("name", values.fullname);
          window.location.href = "/dashboard";
        } else {
          alert(res.message);
        }
      });
    } else {
      alert("Both password should be same");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Container>
      <h3 className="signin-header text-center">Create User Account</h3>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className="signupMainContainer">
          <Form
            name={"complex"}
            layout="vertical"
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: "85%",
              marginTop: "50px",
              height: "400px",
              marginLeft: "7.5%",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Full Name"
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Please input your fullname",
                },
              ]}
            >
              <Input
                placeholder={"Full Name"}
                prefix={<TfiEmail color="#00817f" />}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                placeholder={"Email Address"}
                prefix={<TfiEmail color="#00817f" />}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password prefix={<CiLock color="#00817f" />} />
            </Form.Item>
            <Form.Item
              label="Re-enter Password"
              name="re_pass"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password prefix={<CiLock color="#00817f" />} />
            </Form.Item>
            <Form.Item>
              <p style={{ lineHeight: "20px" }}>
                By continuing to sign up have have agreed to our{" "}
                <a
                  onClick={() => setShowTerm(true)}
                  className="forgotPassword"
                  style={{ color: "#00817f" }}
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  onClick={() => setShowPrivacy(true)}
                  className="forgotPassword"
                  style={{ color: "#00817f" }}
                >
                  Privacy Policy
                </a>{" "}
              </p>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" className="signupBtn">
                Sing Up
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              textAlign: "center",
              left: "20%",
            }}
          >
            <center>
              <p className="signup_link text-center">
                Already have an account?{" "}
                <a
                  href={"/signin"}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <strong>Sign In</strong>
                </a>
              </p>
            </center>
          </div>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Modal
        title="Terms and Condition"
        open={showTerm}
        onOk={handleTerm}
        onCancel={handleCancelTerm}
      >
        <p>
          1. Introduction <br></br> The Address Book application is provided for
          testing purposes as part of a final year project. By using this
          application, you agree to these terms and conditions.
        </p>
        <p>
          2. Data Usage <br></br> Any data you enter into the Address Book will
          be used solely for testing and evaluation purposes related to the
          project. Your data will not be shared with third parties or used for
          any other purpose.
        </p>
        <p>
          3. Data Deletion <br></br> Upon completion of the final year project,
          all data entered into the Address Book will be permanently deleted.
        </p>
        <p>
          4. Responsibilities <br></br> You are responsible for the accuracy and
          legality of the data you enter into the Address Book. Ensure that you
          have the right to share any personal information that you input.
        </p>
        <p>
          5. Feedback <br></br> Your feedback and suggestions regarding the
          Address Book application are welcomed and may be used to improve the
          project.
        </p>
        <p>
          6. Contact Information <br></br> If you have any questions or concerns
          regarding these terms and conditions, please contact
          olisa@chukwuma.com
        </p>
      </Modal>
      <Modal
        title="Privacy Policy"
        open={showPrivacy}
        onOk={handlePrivacy}
        onCancel={handleCancelPrivacy}
      >
        <p>
          1. Information Collection <br></br> We collect personal information
          (such as name, email address, phone number) that you voluntarily enter
          into the Address Book for testing purposes.
        </p>
        <p>
          2. Use of Information <br></br> Your personal information is used
          solely for testing and evaluation purposes related to the Address Book
          project. We do not share your information with third parties.
        </p>
        <p>
          4. Data Retention <br></br> Upon completion of the final year project,
          all personal information entered into the Address Book will be deleted
          from our systems.
        </p>
        <p>
          5. Cookies and Tracking <br></br> The Address Book application may use
          cookies for session management and user authentication purposes. These
          cookies do not collect personal information and are deleted once you
          log out.
        </p>
        <p>
          6. Changes to Privacy Policy <br></br> Any changes to this privacy
          policy will be posted on this page. You are encouraged to review this
          policy periodically.
        </p>
        <p>
          7. Contact Information <br></br> If you have any questions or concerns
          regarding this privacy policy or our practices, please contact
          olisa@chukwuma.com
        </p>
        <p></p>
      </Modal>
    </Container>
  );
}
