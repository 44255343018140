import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Sidebar from "../../../component/Common/Sidebar/ADMIN/Sidebar";
import UsersList from "./UsersList";
import { BrowserView, MobileView } from "react-device-detect";

export default function Users() {
  return (
    <Container fluid className={"mainContainer"}>
      <BrowserView>
        <Row>
          <Col md={2} style={{ padding: "0px", margin: "0px" }}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <main>
              <Container fluid>
                <UsersList />
              </Container>
            </main>
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <UsersList />
      </MobileView>
    </Container>
  );
}
