import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Sidebar from "../../component/Common/Sidebar/Sidebar";
import AddressBookList from "./AddressBookList";
import { BrowserView, MobileView } from "react-device-detect";

export default function AppUser() {
  return (
    <Container fluid className={"mainContainer"}>
      <BrowserView>
        <Row>
          <Col md={2} style={{ padding: "0px", margin: "0px" }}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <main>
              <Container fluid>
                <AddressBookList />
              </Container>
            </main>
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <AddressBookList />
      </MobileView>
    </Container>
  );
}
