import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import Sidebar from "../../../component/Common/Sidebar/ADMIN/Sidebar";
import { RiContactsBook2Line } from "react-icons/ri";
import { IoIosArrowDropright } from "react-icons/io";
import { psApiCalling } from "../../../component/API";

export default function Dashboard() {
  const [name, setName] = useState(localStorage.getItem("aname"));
  const [code, setCode] = useState(localStorage.getItem("aid"));
  const [number, setNumber] = useState(0);
  let arr = [
    {
      id: 13,
      menu: "Users",
      link: "/users",
    },
    {
      id: 130,
      menu: "Logout",
      link: "/logout",
    },
  ];

  const getAppUserList = () => {
    let params = {
      action: "GET_USER_LIST",
    };
    psApiCalling(params).then((res) => {
      console.log(res);
      if (Array.isArray(res)) {
        setNumber(res.length);
      }
    });
  };

  useEffect(() => {
    getAppUserList();
  }, []);

  return (
    <Container fluid style={{ padding: "0px" }}>
      <MobileView>
        <h4 className="mobile-user-dashboard-text text-center">
          User Dashboard
        </h4>
        <p className="mobile-fullname text-center">Welcome, {name}</p>

        <Container fluid className="dasboard-mobile-container">
          <center>
            <div className="counter-box">
              <br></br>
              <RiContactsBook2Line color="#FFFFFF" size={50} />
              <h2 className="num-Counter">{number}</h2>
              <p className="counter-text">Total Users</p>
            </div>
          </center>
        </Container>
        <Container fluid className="dashboard-mobile-menu-container">
          <Row>
            {arr.map((item, index) => {
              return (
                <a key={index} href={item.link} className="menu-link">
                  <Col md={12} className="item-menu">
                    <p>{item.menu}</p>
                    <div style={{ position: "absolute", right: "10px" }}>
                      <IoIosArrowDropright size={30} />
                    </div>
                  </Col>
                </a>
              );
            })}
          </Row>
        </Container>
      </MobileView>
      <BrowserView>
        <Row>
          <Col md={2} className="sidbar-dashboard">
            <Sidebar to={window.location.pathname} />
          </Col>
          <Col md={8}>
            <h3 className="dashboard-title text-center">
              Dashboard Statistics
            </h3>
            <center>
              <div className="counter-box">
                <br></br>
                <RiContactsBook2Line color="#FFFFFF" size={50} />
                <h2 className="num-Counter">{number}</h2>
                <p className="counter-text">Total Users</p>
              </div>
            </center>
          </Col>
          <Col md={2}>
            <div style={{ marginTop: "60px" }}>
              <p className="fullname-dashboard text-center">Welcome, {name}</p>
              <p className="fullname-dashboard text-center">{code}</p>
            </div>
          </Col>
        </Row>
      </BrowserView>
    </Container>
  );
}
