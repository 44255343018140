import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Card, Space, Button, Tag } from "antd";
import { Checkbox, Form, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../../component/Table/Table";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { psApiCalling } from "../../../component/API";

export default function UsersList() {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [col, setCol] = useState([
    {
      title: "#",

      key: "id",
      render: (_, record) => <>{_.id}</>,
    },
    {
      title: "User ID",

      key: "uid",
      render: (_, record) => <>{_.uid}</>,
    },
    {
      title: "Added Date/Time",
      key: "time",
      render: (_, record) => <Tag color={"purple"}>{_.time}</Tag>,
    },
    {
      title: "Full Name",
      key: "fullname",
      render: (_, record) => <>{_.name}</>,
    },

    {
      title: "Email Address",
      key: "emailAddress",
      render: (_, record) => <Tag color={"green"}>{_.email}</Tag>,
    },
    {
      title: "Contacts",
      key: "phone",
      render: (_, record) => <Tag color={"#1e3799"}>{_.contact}</Tag>,
    },
  ]);
  const [uid, setUid] = useState(null);

  const showModal = () => {
    setShowAdd(true);
  };
  const handleOk = () => {
    setShowAdd(false);
  };
  const handleCancel = () => {
    setShowAdd(false);
  };
  const handleUpdateOk = () => {
    setShowUpdate(false);
  };
  const handleUpdateCancel = () => {
    setShowUpdate(false);
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    let params = {
      action: "ADD_CONTACT",
      fullname: values.fullname,
      email: values.email_address,
      phone: values.phone_number,
      by: localStorage.getItem("uniid"),
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        getAppUserList();
        setShowAdd(false);
      } else {
        alert(res.message);
      }
    });
  };
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState([]);
  const filter = (q) => {
    const filteredItems = data.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(q.toLowerCase()) !== -1
    );
    setFilterData(filteredItems);
  };

  const getAppUserList = () => {
    let params = {
      action: "GET_USER_LIST",
    };
    psApiCalling(params).then((res) => {
      console.log(res);
      if (Array.isArray(res)) {
        setData(res);
        setFilterData(res);
      }
    });
  };

  const onFinishUpdate = (values) => {
    let params = {
      action: "UPDATE_CONTACT",
      cid: updateId,
      email: values.email_address,
      phone: values.phone_number,
      fullname: values.fullname,
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        alert(res.message);
        setShowUpdate(false);
        getAppUserList();
      } else {
        alert(res.message);
      }
    });
  };

  const deleteItem = (id) => {
    let params = { action: "DELET_ITEM", id: id };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        alert(res.message);
        setShowUpdate(false);
        getAppUserList();
      } else {
        alert(res.message);
      }
    });
  };

  useEffect(() => {
    getAppUserList();
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <Box style={{ marginTop: "8px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          md={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid
            md={12}
            style={{ width: "100%", marginTop: "0px" }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <MobileView>
              <br></br>
              <a href={"/admin_dashboard"} style={{ margin: "20px" }}>
                <IoChevronBackCircleOutline size={30} />
              </a>
            </MobileView>
            <Card
              title="Users"
              style={{
                width: "100%",
                marginLeft: "1%",
                marginTop: "30px",
                overflow: "scroll",
              }}
            >
              <BrowserView>
                <Table data={data} col={col} />
              </BrowserView>
              <MobileView>
                <div>
                  <Input
                    placeholder={"Search"}
                    style={{ width: "100%", marginBottom: 10 }}
                    onChange={(v) => filter(v.target.value)}
                  />
                </div>
                <table border={1} style={{ width: "100%" }}>
                  <tbody>
                    {filterData.map((item) => {
                      return (
                        <>
                          <tr style={{ width: "100%", height: "50px" }}>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              #
                            </td>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              {item.id}
                            </td>
                          </tr>
                          <tr style={{ width: "100%", height: "50px" }}>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              User ID
                            </td>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              <Tag color="#16a085">{item.uid}</Tag>
                            </td>
                          </tr>
                          <tr style={{ width: "100%", height: "50px" }}>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              Added Time
                            </td>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              <Tag color="purple">{item.time}</Tag>
                            </td>
                          </tr>

                          <tr style={{ width: "100%", height: "50px" }}>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              Full Name
                            </td>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              <Tag color="#27ae60">{item.name}</Tag>
                            </td>
                          </tr>

                          <tr style={{ width: "100%", height: "50px" }}>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              Email
                            </td>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              <Tag color="#2c3e50">{item.email}</Tag>
                            </td>
                          </tr>
                          <tr style={{ width: "100%", height: "50px" }}>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              Contact
                            </td>
                            <td
                              style={{
                                border: "1px solid #cdcdcd",
                                paddingLeft: "10px",
                              }}
                            >
                              <Tag color="#2c3e50">{item.contact}</Tag>
                            </td>
                          </tr>

                          <br></br>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </MobileView>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
      <Modal
        title="Add Contact to Address Book"
        open={showAdd}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name={"complex"}
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: "100%",
            marginTop: "50px",
            height: "400px",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Full Name"
            name="fullname"
            rules={[
              {
                required: true,
                message: "Please input full name",
              },
            ]}
          >
            <Input placeholder={"Enter Full Name"} />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
          >
            <Input placeholder={"Enter Phone Number"} />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email_address"
            rules={[
              {
                required: true,
                message: "Please input your email address",
              },
            ]}
          >
            <Input placeholder={"Enter Email Address"} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit" className="loginBtn">
              ADD
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Contact"
        open={showUpdate}
        onOk={handleUpdateOk}
        onCancel={handleUpdateCancel}
      >
        <Form
          form={form}
          name={"complex"}
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: "100%",
            marginTop: "50px",
            height: "400px",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishUpdate}
          autoComplete="off"
        >
          <Form.Item
            label="Full Name"
            name="fullname"
            rules={[
              {
                required: true,
                message: "Please input full name",
              },
            ]}
          >
            <Input placeholder={"Enter Full Name"} />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
          >
            <Input placeholder={"Enter Phone Number"} />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email_address"
            rules={[
              {
                required: true,
                message: "Please input your email address",
              },
            ]}
          >
            <Input placeholder={"Enter Email Address"} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit" className="loginBtn">
              UPDATE
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Box>
  );
}
