import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { Checkbox, Form, Input } from "antd";
import { TfiEmail } from "react-icons/tfi";
import { CiLock } from "react-icons/ci";
import { psApiCalling } from "../../component/API/index";
import "./Signin.css";
export default function Signin() {
  const onFinish = (values) => {
    console.log("Success:", values);
    let params = {
      action: "USER_LOGIN",
      email: values.email,
      password: values.password,
    };
    psApiCalling(params).then((res) => {
      if (res.status === "success") {
        localStorage.setItem("uniid", res.id);
        localStorage.setItem("name", res.name);
        window.location.href = "/dashboard";
      } else {
        alert(res.message);
      }
    });
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <Container>
      <h3 className="signin-header text-center">User Login</h3>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className="splashMainContainer">
          <Form
            name={"complex"}
            layout="vertical"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: "85%",
              marginTop: "50px",
              height: "400px",
              marginLeft: "7.5%",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                placeholder={"Email Address"}
                prefix={<TfiEmail color="#00817f" />}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password prefix={<CiLock color="#00817f" />} />
            </Form.Item>
            <Form.Item>
              <a href={"/forgot_password"} className="forgotPassword">
                Forgot Password
              </a>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" className="loginBtn">
                Login
              </Button>
            </Form.Item>
          </Form>
          <p className="signup_link text-center">
            Don't have an account?{" "}
            <a
              href={"/signup"}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <strong>Sign Up</strong>
            </a>
          </p>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}
