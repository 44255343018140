import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { Checkbox, Form, Input } from "antd";
import { TfiEmail } from "react-icons/tfi";
import { CiLock } from "react-icons/ci";
import { psApiCalling } from "../../component/API/index";

export default function UpdatePassword() {
  const onFinish = (values) => {
    console.log("Success:", values);
    if (values.password === values.re_pass) {
      let params = {
        action: "UPDATE_PASWORD",

        email: localStorage.getItem("femail"),
        password: values.password,
      };
      psApiCalling(params).then((res) => {
        if (res.status === "success") {
          alert(res.message);
          window.location.href = "/signin";
        } else {
          alert(res.message);
        }
      });
    } else {
      alert("Both password should be same");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Container>
      <h3 className="signin-header text-center">Forgot Paasword</h3>
      <p className="text-center">Please create your new password</p>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className="signupMainContainer">
          <Form
            name={"complex"}
            layout="vertical"
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: "85%",
              marginTop: "50px",
              height: "400px",
              marginLeft: "7.5%",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password prefix={<CiLock color="#00817f" />} />
            </Form.Item>
            <Form.Item
              label="Re-enter Password"
              name="re_pass"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password prefix={<CiLock color="#00817f" />} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" className="signupBtn">
                Change Password
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              textAlign: "center",
              left: "20%",
            }}
          >
            <center>
              <p className="signup_link text-center">
                Already have an account?{" "}
                <a
                  href={"/signin"}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <strong>Sign In</strong>
                </a>
              </p>
            </center>
          </div>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}
