import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { Checkbox, Form, Input } from "antd";
import { TfiEmail } from "react-icons/tfi";
import { CiLock } from "react-icons/ci";
import { psApiCalling } from "../../component/API/index";

export default function OTP() {
  const onFinish = (values) => {
    if (parseInt(values.my_otp) === parseInt(localStorage.getItem("otp"))) {
      window.location.href = "/updatePassword";
    } else {
      alert("Please enter valid otp");
    }
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <Container>
      <h3 className="signin-header text-center">Forgot Paasword</h3>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className="splashMainContainer">
          <p className="text-center">
            We have sent 4-Digit Otp Code to your email{" "}
            {localStorage.getItem("femail")} Please verify your email address by
            entering that 4-Digit Code
          </p>
          <Form
            name={"complex"}
            layout="vertical"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: "85%",
              marginTop: "50px",
              height: "200px",
              marginLeft: "7.5%",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="4-Digit Code:"
              name="my_otp"
              rules={[
                {
                  required: true,
                  message: "Please input 4-Digit OTP",
                },
              ]}
            >
              <Input.Password
                placeholder={"XXXX"}
                prefix={<CiLock color="#00817f" />}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" className="loginBtn">
                Verify Code
              </Button>
            </Form.Item>
          </Form>
          <p className="signup_link text-center">
            Don't have an account?{" "}
            <a
              href={"/signup"}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <strong>Sign Up</strong>
            </a>
          </p>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}
