import { MAIN_URL } from "../Connection/index";
import axios from "axios";
const psApiCalling = (params) => {
  return fetch(`${MAIN_URL}`, {
    method: "POST",
    body: JSON.stringify(params),
    // mode: "no-cors",
    headers: {
      "Content-type": "application/json;charset=utf-8",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: "error",
        message: "API_ERROR " + error,
      };
    });
};

export { psApiCalling };
