import React, { useEffect } from "react";

export default function Logout() {
  const doLogout = () => {
    localStorage.removeItem("uniid");
    localStorage.removeItem("name");
    localStorage.removeItem("aid");
    localStorage.removeItem("aname");
    window.location.href = "/";
  };
  useEffect(() => {
    doLogout();
  });

  return <div>Logout.....</div>;
}
