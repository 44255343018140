import React, { useState, useEffect } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CBadge,
  CNavGroup,
  CSidebarToggler,
} from "@coreui/react";
import {
  MdSpaceDashboard,
  MdLeaderboard,
  MdOutlineAdminPanelSettings,
  MdSupervisedUserCircle,
  MdHomeRepairService,
  MdOutlineFavoriteBorder,
} from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { CgCalendarToday } from "react-icons/cg";
import { AiOutlinePullRequest } from "react-icons/ai";
import { GrStatusGood } from "react-icons/gr";
import { FaIndustry } from "react-icons/fa";
import { TbStatusChange } from "react-icons/tb";
import { MdReport } from "react-icons/md";

import { Box } from "@mui/material";
import "./Sidebar.css";

export default function Sidebar(props) {
  const { to } = props;

  const [menu, setMenu] = useState([]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  console.log("/dashboard" === to);

  const menuUpdate = () => {
    setMenu([
      {
        id: 1,
        menu: "Admin Dashboard",
        link: "/admin_dashboard",

        icon: (
          <MdSpaceDashboard
            customClassName="nav-icon"
            style={{ marginRight: "10px" }}
            color={"#ffffff"}
          />
        ),
      },
      {
        id: 13,
        menu: "Users",
        link: "/users",
        icon: (
          <CgCalendarToday
            customClassName="nav-icon"
            style={{ marginRight: "10px" }}
            color={"#ffffff"}
          />
        ),
      },
      {
        id: 130,
        menu: "Logout",
        link: "/logout",
        icon: (
          <MdReport
            customClassName="nav-icon"
            style={{ marginRight: "10px" }}
            color={"#ffffff"}
          />
        ),
      },
    ]);
  };
  //14
  useEffect(() => {
    menuUpdate();
  }, []);

  return (
    <div style={{ padding: "0px", margin: "0px" }}>
      <Box md={{ width: "100%" }}>
        <CSidebar
          style={{
            width: "100%",
            minHeight: "100vh",
            position: "relative",
            padding: "10px",
          }}
          className="adminSidebar"
          colorScheme="dark"
        >
          <CSidebarNav
            style={{
              display: "flex",
              overflow: "auto",
            }}
          >
            {menu.map((item) => {
              return (
                <CNavItem
                  href={item.link}
                  active={item.link === to ? true : false}
                  style={{ color: "#FFFFFF" }}
                >
                  {item.icon} {item.menu}
                </CNavItem>
              );
            })}
          </CSidebarNav>
        </CSidebar>
      </Box>
    </div>
  );
}
