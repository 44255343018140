import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import "./Splash.css";
export default function Splash() {
  return (
    <Container>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className="splashMainContainer">
          <h3 className="splashTitle text-center">
            Welcome to Your Address Book
          </h3>
          <p className="splashPara text-center">
            Manage your contacts efficiently and effortlessly.
          </p>
          <div style={{ marginTop: "20%" }}>
            <p className="splashPara text-center" style={{ color: "#00817f" }}>
              {" "}
              Sign in to manage your Address Book
            </p>
            <Button
              href={"/signin"}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#00817f",
                color: "#FFFFFF",
                textDecoration: "none",
              }}
            >
              Sign In
            </Button>
          </div>
          <div style={{ marginTop: "10%" }}>
            <p className="splashPara text-center" style={{ color: "#00817f" }}>
              {" "}
              Don't have a user account ?
            </p>
            <Button
              href={"/signup"}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#00817f",
                color: "#FFFFFF",
                textDecoration: "none",
              }}
            >
              Create an account
            </Button>
          </div>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  );
}
