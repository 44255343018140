import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Splash from "../Splash/Splash";
import Signin from "../pages/Signin/Signin";
import Signup from "../pages/Signup/Signup";
import Dashboard from "../pages/Dasboard/Dashboard";
import Logout from "../pages/Logout/Logout";
import AddressBook from "../pages/AddressBook/AddressBook";
import ForgotPassword from "../pages/Forgot/ForgotPassword";
import OTP from "../pages/OTP/OTP";
import UpdatePassword from "../pages/UpdatePassword/UpdatePassword";
import AdminLogin from "../pages/admin/Login/Login";
import AdminDashboard from "../pages/admin/Dashboard/Dashboard";
import AdminOTP from "../pages/admin/AdminOTP/AdminOTP";
import Users from "../pages/admin/Users/Users";
export default function Index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splash />}></Route>
        <Route path="/signin" element={<Signin />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/addressBook" element={<AddressBook />}></Route>
        <Route path="/forgot_password" element={<ForgotPassword />}></Route>
        <Route path="/otp" element={<OTP />}></Route>
        <Route path="/updatePassword" element={<UpdatePassword />}></Route>
        <Route path="/admin" element={<AdminLogin />}></Route>
        <Route path="/admin_dashboard" element={<AdminDashboard />}></Route>
        <Route path="/admin_otp" element={<AdminOTP />}></Route>
        <Route path="/users" element={<Users />}></Route>
      </Routes>
    </Router>
  );
}
